import * as React from 'react';

import NewsPage from 'components/news/Page';

import client from "../../../sanity-client-config"
import Loader from '../../components/utils/Loader';

const getQuery = (docId) => `*[_id == "${docId}"]{
    "id": _id,
    slug {
        current
    },
    category-> {
        _id,
        title {
            se,
            en
        },
        ref
    },
    team[]-> {
        _id,
        title {
            se,
            en
        }
    },
    language-> {
        hreflang
    },
    image {
        defaultImage,
        teaserImage
    },
    video {
        teaserVideo,
        teaserVideoPreview
    },
    content[] {
        ...,
        "blockType": _type,
        "blockContent": @,
        "blockEditorImage": *[_type == "blockEditorImage"] {
            image,
            alt
        },
        "blockEditorSolidtango": *[_type == "blockEditorSolidtango"] {
            url
        },
        "blockEditorSEF": *[_type == "blockEditorSEF"] {
            url
        },
        "blockEditorStaylive": *[_type == "blockEditorStaylive"] {
            url
        },
        "blockEditorYoutube": *[_type == "blockEditorYoutube"] {
            url
        },
        "blockText": *[_type == "block"] {
            children[] {
                text
            }
        }
    },
    _createdAt,
    publishDate,
    title,
    "_rawContent": content
}`;

const Preview = ({ id }) => {
    const [doc, setDoc] = React.useState(null);

    React.useEffect(() => {
        client.fetch(getQuery(`drafts.${id}`))
            .then((res) => {
                if (Array.isArray(res) && res.length > 0) {
                    setDoc(res[0]);
                } else {
                    return client.fetch(getQuery(id));
                }
                return null;
            })
            .then((res) => {
                if (res && Array.isArray(res) && res.length > 0) {
                    setDoc(res[0]);
                }
                return null;
            })
            .catch(err => console.error(err))
    }, [id])

    if(doc != null){
        return <NewsPage news={doc}/>
    }

    return <Loader message="Loading..." />
};

export default Preview;
