import React, { useState } from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import * as style from 'styles/components/utils/unibetBanner.module.scss';
import useSanityConfigGlobal from 'data/queries/useSanityConfigGlobal';
import FormattedMessage from 'components/utils/FormattedMessage';

const UnibetBanner = () => {
    const sanityConfigGlobal = useSanityConfigGlobal();
    const sponsorBanner = sanityConfigGlobal?.leagueMenSponsorBanner;

    const [isMobile, setIsMobile] = useState(
        typeof window !== 'undefined' && window.innerWidth < 1100,
    );

    React.useEffect(() => {
        const setMobile = () => {
            if (typeof window !== 'undefined') {
                setIsMobile(window.innerWidth < 1100);
            }
        };

        window.addEventListener('resize', setMobile);

        return () => {
            window.removeEventListener('resize', setMobile);
        };
    }, []);

    return (
        <div className={style.banner}>
            {sponsorBanner && (
                <div className={style.banner__container}>
                    {sponsorBanner?.desktopImage && (
                        <div className={style.banner__desktop}>
                            <div className={style.banner__adMark}>
                                <FormattedMessage id="global.ad" />
                            </div>

                            {sponsorBanner?.url && (
                                <a href={sponsorBanner.url} target="_blank" rel="noreferrer">
                                    <ImageGatsby
                                        {...sponsorBanner.desktopImage}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                        }}
                                        alt="banner"
                                    />
                                </a>
                            )}

                            {!sponsorBanner.url && (
                                <ImageGatsby
                                    {...sponsorBanner.desktopImage}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                    alt="banner"
                                />
                            )}
                        </div>
                    )}

                    {sponsorBanner?.mobileImage && (
                        <div className={style.banner__mobile}>
                            <div className={style.banner__adMark}>
                                <FormattedMessage id="global.ad" />
                            </div>
                            {sponsorBanner?.url && (

                                <a href={sponsorBanner.url} target="_blank" rel="noreferrer">
                                    <ImageGatsby
                                        {...sponsorBanner.mobileImage}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                        }}
                                        alt="banner"
                                    />
                                </a>
                            )}

                            {!sponsorBanner.url && (
                                <ImageGatsby
                                    {...sponsorBanner.mobileImage}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                    alt="banner"
                                />
                            )}
                        </div>
                    )}
                    { isMobile&&
                        <div className={style.banner__demo}>
                            Annons
                        </div>
                    }
                </div>
            )}
        </div>
    );
};

export default UnibetBanner;
