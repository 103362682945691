import React, { useContext, useEffect } from 'react';
// import MetaTags from 'react-meta-tags';
import { navigate } from 'gatsby';

import { LangContext } from 'context/LangContext';
import { fromPortableToText } from 'data/utils';
import EmbedVideoPlayer from 'components/utils/EmbedVideoPlayer';
import UnibetBanner from 'components/utils/UnibetBanner';
import Image from 'components/utils/Image';
import TextEditorRender from 'components/utils/TextEditorRender';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';
import enMessages from 'i18n/en.json';
import seMessages from 'i18n/se.json';
import * as style from 'styles/components/news/page.module.scss';

const MESSAGES = {
    en: enMessages,
    se: seMessages,
};

const NewsPage = ({ news }) => {
    const { lang } = useContext(LangContext);

    let publicationDate = new Date(news._createdAt);

    if(news.publishDate ) {
        publicationDate = new Date(news.publishDate);
    }
    const formattedPublicationDate = publicationDate.toLocaleDateString(lang.iso, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });

    const url = typeof window !== 'undefined' ? window.location.href : '';

    const shareSubject = getFormattedMessage('news.shareEmailSubject', lang)
    const shareBody = `${getFormattedMessage('news.shareEmailBody', lang)} ${news.title} - ${url}`

    let isMenTeam = false;

    useEffect(() => {
        // react-helmet does not prepend so need to do it manually
        const meta = document.createElement('meta');
        meta.setAttribute('property', 'og:image');
        meta.setAttribute('value', news.image.defaultImage.image?.asset?.url);
        const head = document.querySelector('head');
        head.insertBefore(meta, head.firstChild);

        const meta2 = document.createElement('meta');
        meta2.setAttribute('property', 'og:content');
        meta2.setAttribute('value', 'article');
        head.insertBefore(meta2, head.firstChild);

        const meta3 = document.createElement('meta');
        meta3.setAttribute('property', 'og:site_name');
        meta3.setAttribute('value', 'Hammarby Fotboll');
        head.insertBefore(meta3, head.firstChild);

        const meta4 = document.createElement('meta');
        meta4.setAttribute('property', 'og:url');
        meta4.setAttribute('value', url);
        head.insertBefore(meta4, head.firstChild);

        const meta5 = document.createElement('meta');
        meta5.setAttribute('property', 'og:description');
        meta5.setAttribute('value', fromPortableToText(news._rawContent).slice(0, 197));
        head.insertBefore(meta5, head.firstChild);

        const meta6 = document.createElement('meta');
        meta6.setAttribute('property', 'og:title');
        meta6.setAttribute('value', news.title);
        head.insertBefore(meta6, head.firstChild);
    }, [news, url]);

    if(news.team.length > 0) {
        news.team.forEach(team => {
            if( team.axsType === 'men' ) {
                isMenTeam = true;
            }
        });
    }

    return (
        <>

            <div className={style.header}>
                {news.image?.defaultImage?.image?.asset &&
                    <Image image={news.image.defaultImage} width="2000" />
                }
                <div className={style.header__content}>
                    <div className="wrapper-s">
                        <p className="text-xs text-medium uppercase">
                            <span>{formattedPublicationDate}</span>
                            {news.readingTime && (
                                <span>{`• ${news.readingTime} ${
                                    MESSAGES[lang.translationKey].news.minutesRead
                                }`}</span>
                            )}
                        </p>
                        <h3 className="title-sm-mobile title-xl text-bold">{news.title}</h3>
                    </div>
                </div>
            </div>

            <div className={style.content}>
                <ul className={`${style.share} wrapper text-darker`}>
                    <li>
                        <a
                            href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                            target="_blank"
                            rel="noreferrer"
                            title={MESSAGES[lang.translationKey].news.shareFacebook}
                            aria-label={MESSAGES[lang.translationKey].news.shareFacebook}
                        >
                            <i className="ico-facebook" aria-hidden />
                            <span className="visually-hidden">
                                <FormattedMessage id="news.facebook" />
                            </span>
                        </a>
                    </li>
                    <li>
                        <a
                            href={`https://twitter.com/share?url=${url}&text=${news.title}`}
                            target="_blank"
                            rel="noreferrer"
                            title={MESSAGES[lang.translationKey].news.shareTwitter}
                            aria-label={MESSAGES[lang.translationKey].news.shareTwitter}
                        >
                            <i className="ico-twitter" aria-hidden />
                            <span className="visually-hidden">
                                <FormattedMessage id="news.twitter" />
                            </span>
                        </a>
                    </li>
                    <li>
                        <a
                            href={`mailto:?subject=${shareSubject}&body=${shareBody}`}
                            target="_blank"
                            rel="noreferrer"
                            title={MESSAGES[lang.translationKey].news.shareEmail}
                            aria-label={MESSAGES[lang.translationKey].news.shareEmail}
                        >
                            <i className="ico-send" aria-hidden />
                            <span className="visually-hidden">
                                <FormattedMessage id="news.mail" />
                            </span>
                        </a>
                    </li>
                </ul>
                <div className="wrapper-s">

                    <button type="button" className="cta-white-sec small-margin" onClick={() => navigate(-1)}>
                        {lang === 'en' ? 'Go back' : 'Gå tillbaka'}
                    </button>

                    {isMenTeam && (
                        <UnibetBanner />
                    )}

                    <TextEditorRender content={news._rawContent} />
                    {news?.video?.teaserVideo?.url && (
                        <div className={style.video}>
                            <EmbedVideoPlayer url={news.video.teaserVideo.url} title={news.title} />
                        </div>
                    )}
                    <div className={`${style.tags} text-center`}>
                        {news.category?.title && news.category.title[lang.translationKey] && (
                            <p className={`${style.tag} active`}>
                                {news.category.title[lang.translationKey]}
                            </p>
                        )}
                        {news.team && news.team
                            .filter((item) => item.title && item.title[lang.translationKey])
                            .map((item, index) => (
                                <p key={index} className={style.tag}>{item.title[lang.translationKey]}</p>
                            ))
                        }
                    </div>

                    <button type="button" className="cta-white-sec small-margin-top" onClick={() => navigate(-1)}>
                        {lang === 'en' ? 'Go back' : 'Gå tillbaka'}
                    </button>
                </div>
            </div>
        </>
    );
};

export default NewsPage;
